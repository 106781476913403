<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <b-img v-if="img_icon" class="img-icon flex-shrink-0" :src="img_icon"/>
        <feather-icon
          v-if="icon"
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="w-100">
        <div class="d-flex flex-row">
          <h5
            v-if="title"
            class="mb-0 toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <span
            class="cursor-pointer toastification-close-icon ml-3 mt-0"
            @click="$emit('close-toast')"
          >
            <feather-icon
              v-if="!hideClose"
              icon="XIcon"
              size="15"
              class="close-toast"
            />
          </span>
        </div>
        <div class="mt-1 d-flex flex-row align-items-center" v-if="text">
          <small
            v-if="text"
            class="d-inline-block text-body"
          > {{ text }} <b-link v-if="textLink" @click="$emit('clickLink')"> {{ textLink }}</b-link> </small>
          <div class="w-full text-right" v-if="showButton">
            <b-button :variant="`outline-${variant}`" @click="$emit('clickButton')">{{textButton}}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton, BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BButton,
    BImg,
    BLink
  },
  props: {
    img_icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false
    },
    textButton: {
      type: String,
      default: ''
    },
    textLink: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.img-icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}
.close-toast {
  position: absolute;
  right: 3px;
  top: 17px;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}
.close-toast:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 100%;
  cursor: pointer;
}
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
